import request from "@/utils/request";
// const ipBaseURL = "https://test-huanxinxiang.ai-me.cn";
const ipBaseURL = "https://test-shuadan.ai-me.cn";  //一分钱特惠福利，广通刷单页，已转移到小程序，现废弃
const appName = "huanxinxiang-guangtong";

// 发送短信
export const code = (data) => request({
  url: '/huanxinxiang-service/v1/code/',
  method: 'post',
  headers: {
    "App-Name": appName,
  },
  data,
});

// 支付宝签约
export const codeDlipayPay = (data) => request({
  url: '/huanxinxiang-service/v1/alipay-sms-pay/',
  method: 'post',
  headers: {
    "App-Name": appName,
    "Referrer": ''
  },
  data,
});


// 发送短信带回传名称
export const codeApi = (data) => request({
  url: '/huanxinxiang-service/v1/code/',
  method: 'post',
  // ipBaseURL: ipBaseURL,
  headers: {
    "App-Name": appName,
  },
  data,
});
// 支付宝签约带回传名称
export const alipayCallbackSmspay = (data) => request({
  url: '/huanxinxiang-service/v1/alipay-callback-sms-pay/',
  method: 'post',
  // ipBaseURL: ipBaseURL,
  headers: {
    "App-Name": appName,
    "Referrer": ''
  },
  data,
});

// 发起上报签约
export const alipayCallbackPayAPI = (data) => request({
  url: '/huanxinxiang-service/v1/alipay-callback-pay/',
  method: 'post',
  headers: {
    "App-Name": appName,
    "Referrer": ''
  },
  data,
});

// 一分钱特惠福利 支付宝签约
export const alipayPay = (data) => request({
  url: '/shuadan-service/v1/alipay-pay/',
  method: 'post',
  ipBaseURL: ipBaseURL,
  headers: {
    "App-Name": 'guangtong',
    // "Referrer" : ''
  },
  data,
});

// 优生活会员 支付宝签约 无需验证码
export const alipayPayNoCode = (data) => request({
  url: '/huanxinxiang-service/v1/alipay-pay/',
  method: 'post',
  headers: {
    "App-Name": appName
  },
  data,
});
